<!--ver 1.0-->
<template>
  <v-card v-if="permission.access || permission.show"
          :loading="loading"
          class="mt-3 ml-1">
    <v-toolbar
        style="border-bottom: 2px solid #ececec;"
        color="#fafafa"
        flat
        dense
    >
      <slot name="win-title-help"/>
      <v-toolbar-title class="grey--text text--lighten-2">
        <slot name="win-title-left"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <slot name="win-title-right"/>
      <!--
            <v-text-field
                hide-details
                single-line
            ></v-text-field>

            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
      -->
      <!--
            <v-btn icon>
              <v-icon class="grey--text text--lighten-2">mdi-dots-vertical</v-icon>
            </v-btn>
      -->
    </v-toolbar>
    <v-container style="min-height: 30vh;" class="innershadow px-0 pa-sm-5" fluid>
      <slot v-if="permission.access"/>
      <div v-else-if="permission.show && permission.message"> {{ permission.message }}</div>
      <div v-else-if="permission.show">доступ заблокирован для данного проекта</div>
    </v-container>

    <v-card-actions v-if="!!this.$slots.actions" style="padding: 15px">
<!--      <v-spacer/>-->
      <slot name="actions" />
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  props: {permission_project: String, loading:Boolean},
  data: () => ({
    // slotItems: this.$slots.default
  }),
  computed: {
    permission() {
      if (this.permission_project) {
        return this.$store.state.project.permission[this.permission_project]
      } else return {access: true}
    },
  }
}
</script>

<style scoped>

.innershadow {
  box-shadow: 0px -4px 12px 0px rgb(224 224 224) inset;
}

</style>

<!--


  <win-block :loading="loading" permission_project="statistics.user">
    <template #win-title-left>Товары магазина</template>

    <template #actions>
      <v-btn >Сохранить PDF</v-btn>
    </template>
  </win-block>

  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },


-->